  .custom-a {
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    text-decoration: underline;
    cursor: pointer !important;
    line-break: anywhere;
  }

  .custom-a:hover {
    color: rgba(var(--bs-link-hover-color-rgb), var(--bs-link-hover-opacity, 1));
  }

  .default-table {
    font-size: 2.2vw; /* Default font size */
  }

  .yolcu tbody tr td {
    font-size: 2.2vw; /* Default font size */
  }

  .tableFont {
    font-size: 1.5vw; /* Default font size */
  }

  @media (min-width: 768px) { /* For screens wider than 768px (larger than a tablet) */
      .yolcu {
        font-size: 0.7vw !important; /* Default font size */
      }

      .default-table {
          font-size: 0.7vw; /* Adjusted font size for larger screens */
      }

      .tableFont {
          font-size: 0.7vw; /* Adjusted font size for larger screens */
      }
  }

  @media (min-width: 1000px) { /* For screens wider than 768px (larger than a tablet) */
    .yolcu {
      font-size: 0.85vw !important; /* Default font size */
    }

    .default-table {
        font-size: 0.85vw; /* Adjusted font size for larger screens */
    }

    .tableFont {
        font-size: 0.85vw; /* Adjusted font size for larger screens */
    }
}

@media (min-width: 2000px) { /* For screens wider than 768px (larger than a tablet) */
  .yolcu {
    font-size: 0.7vw !important; /* Default font size */
  }

  .default-table {
      font-size: 0.7vw; /* Adjusted font size for larger screens */
  }

  .tableFont {
      font-size: 0.7vw; /* Adjusted font size for larger screens */
  }
}

  @media (max-width: 767px) { /* For screens wider than 768px (larger than a tablet) */
    .copy-icon {
      display: none;
    }

    .disappear-on-small {
      display: none;
    }

    .shrink-on-small {
      overflow: hidden; /* Hide the overflowed content */
      text-overflow: ellipsis; /* Show ellipsis (...) for overflowed content */
      white-space: nowrap;
    }
  }

/* Add these styles to the end of the file */

.copy-icon {
  transition: transform 0.3s ease-in-out;
}

.copy-icon:hover {
  transform: scale(1.1);
}

.copy-icon.animate-copy {
  animation: copy-animation 0.5s ease-in-out;
}

@keyframes copy-animation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    color: #28a745;
  }
}

/* Ensure the icon is white in dark mode */
.dark-mode .copy-icon {
  color: white;
}