.App {
  text-align: center;
}

.spinner {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-toggler-icon {
  border-radius: 96%;
  
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.custom-navbar {
  color: #fff !important;
  opacity: 1;
  transition: opacity 0.3s ease-in-out !important;
}

.custom-navbar:hover {
  opacity: 0.6;
}

.bottom-nav-brand {
  color: #fff;
  font-size: 0.5vw;
  font-weight: 400;
}

.bottom-brand-logo {
  width: 125px;
}

.clock {
  color: #fff;
  font-size: 1.2vw;
  font-weight: 700;
}

.login-container {
  margin-top: 20vh
}

@media (max-width: 768px) {
  .clock {
      font-size: 2.5vw;
  }

  .login-container {
    margin-top: 5vh
  }

  .bottom-nav-brand {
    font-size: 0.8vw;
  }

  .bottom-brand-logo {
    width: 75px;
  }
}

@media (max-width: 550px) {
  .bottom-nav-brand {
    color: #fff;
    font-size: 1vw;
  }
}

.custom-marker {
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-ctrl {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
